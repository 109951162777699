import { Image } from '@apmg/mimas';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';
import Nlink from 'next/link';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { linkByTypeAs, analyzeUrl } from 'utils/cjsutils';
import { audioDownloadPrefix } from 'utils/utils';
import { HomeProps } from '../Home';
import CategoryByline from 'components/CategoryByline/CategoryByline';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';

dayjs.extend(utc);
dayjs.extend(timezone);

const MoreNews = ({ radar, collection }: HomeProps) => {
  const showRadar = JSON.parse(radar.json).general.radar_on;
  const startNumber = showRadar ? 6 : 7;

  return (
    <section>
      <div className="home-more-stories home-card">
        <ul className="home-stories">
          {collection?.results.items
            ?.slice(startNumber, startNumber + 6)
            .map((item, i) => {
              return (
                <li
                  className={`home-story ${
                    item?.primaryVisuals?.thumbnail ? 'home-story-has-img' : ''
                  }`}
                  key={i}
                >
                  <Link
                    href={linkByTypeAs(item)}
                    key={item.canonicalSlug}
                    passHref={true}
                  >
                    <h3 className="home-story-hdg type-lg">{item.title}</h3>
                  </Link>
                  {item?.primaryVisuals?.thumbnail && (
                    <Link
                      href={linkByTypeAs(item)}
                      key={item.canonicalSlug}
                      passHref={true}
                      aria-hidden /* prevent duplicate links */
                      tabIndex={-1} /* remove from tab order */
                    >
                      <Image
                        image={item.primaryVisuals.thumbnail}
                        aspectRatio="thumbnail"
                        sizes="(min-width: 72em) 10vw,  80vw"
                        alt={item.title}
                      />
                    </Link>
                  )}
                  <div className="home-story-text">
                    <CategoryByline
                      publishDate={item.publishDate}
                      primaryCollection={item.primaryCollection}
                    />
                    <div className="home-more-story-desc">
                      {item?.descriptionText && <p>{item.descriptionText}</p>}
                      {item.collectionRelatedLinks?.length ? (
                        <ul>
                          {item.collectionRelatedLinks.map((link) => {
                            const { as } = analyzeUrl(link.url);
                            return (
                              <li
                                key={`${link.url}${link.title}${link.prefix}`}
                              >
                                {link.prefix ? (
                                  <span className="related-prefix">
                                    {link.prefix}
                                  </span>
                                ) : (
                                  ''
                                )}
                                <Nlink
                                  href={as || link.url}
                                  className="related_link"
                                >
                                  {link.title}
                                </Nlink>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                      <div className="home-stories-controls">
                        {item?.primaryAudio?.encodings[0] ? (
                          <AudioPlayButton
                            audioTitle={item.title}
                            label="Listen"
                            audioSource={audioDownloadPrefix(
                              item.primaryAudio.encodings[0].playFilePath
                            )}
                            audioThumbnailSrc={
                              item.primaryVisuals.lead?.aspect_ratios.square
                                ?.instances[0].url
                            }
                            audioThumbnailAlt={item.title}
                            showDuration={`${
                              item.primaryAudio.encodings[0].durationHms ?? ''
                            }`}
                            playBtnSm={true}
                          />
                        ) : (
                          <span></span>
                        )}
                        <AddFavoriteWrapper
                          title={item.title}
                          contentArea="mprnews"
                          resourceType={item.resourceType}
                          id={item.id}
                          slug={item.canonicalSlug}
                          showIcon={true}
                          showBackground={false}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="home-stories-bottom">
          <Link
            className="btn btn-primary btn-slim home-btn-more"
            href={`/content/${new Date()
              .toLocaleDateString('en-US')
              .replace(/\//g, '-')}`}
          >
            More latest news →
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MoreNews;
