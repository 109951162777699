import React, { useEffect } from 'react';
import Script from 'next/script';
import Link from 'next/link';
import Nlink from 'next/link';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { linkByTypeAs, analyzeUrl } from 'utils/cjsutils';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { audioDownloadPrefix } from 'utils/utils';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';
import { Image } from '@apmg/mimas';
import { HomeProps } from '../Home';
import { getVideoEmbedUrl } from '../../../utils/utils';
import CategoryByline from 'components/CategoryByline/CategoryByline';

dayjs.extend(utc);
dayjs.extend(timezone);

declare global {
  interface Window {
    wxWidgets?: {
      parse: () => void;
    };
  }
}

const FeaturedStories = ({ collection, radar }: HomeProps) => {
  const stories = collection?.results.items;
  const showRadar = JSON.parse(radar.json).general.radar_on;

  useEffect(() => {
    if (showRadar) {
      if (window.wxWidgets) window.wxWidgets.parse();
    }
  }, []);

  return (
    <article className="grid-primary">
      <div id="mainContent"></div>
      <section id="featured-collection" className="home-main-story">
        {showRadar && (
          <>
            <Script
              src={`https://widgets.media.weather.com/wxwidget.loader.js?cid=431513429&apmId=${Date.now()}`}
              strategy="beforeInteractive"
            />
            <section
              id="radar"
              style={{ height: '600px', marginBottom: '6rem' }}
            >
              <wx-config
                latitude="44.8848"
                longitude="-93.2223"
                group="true"
              ></wx-config>
              <wx-widget
                type="map"
                mapid="0001"
                group="true"
                zoomlevel="8"
                className="content_thumbnail"
              ></wx-widget>

              <div className="teaser">
                <Link href="/weather">
                  <h2 className="hdg hdg-2">
                    {JSON.parse(radar.json).radar_title.title}
                  </h2>
                  <p>{JSON.parse(radar.json).radar_description.title}</p>
                </Link>
              </div>
            </section>
          </>
        )}
        {!showRadar &&
          stories &&
          stories?.slice(0, 1).map((item) => {
            const audioSquareImage =
              item.primaryVisuals.thumbnail?.aspect_ratios.square?.instances[0]
                .url;
            return (
              <div key={item.canonicalSlug}>
                <div className="home-main-story-body">
                  <Link
                    className="home-main-story-title"
                    href={linkByTypeAs(item)}
                    key={item.canonicalSlug}
                    passHref={true}
                  >
                    <h2 className="hdg hdg-1">{item.title}</h2>
                  </Link>
                  <div className="grid grid-2col home-main-story-grid-gap">
                    <Link
                      href={linkByTypeAs(item)}
                      key={item.canonicalSlug}
                      passHref={true}
                      aria-hidden
                      /* Prevent duplicate links.
                        If it was not aria-hidden, its alt would be the story title 
                        due to image link alts describing their destination.
                        Therefore, it's redundant. */
                      tabIndex={-1}
                      /* Since the link is redundant -- and aria-hidden, 
                        it should be removed from keyboard navigation. */
                    >
                      {item.primaryVisuals?.video?.url ? (
                        <div className="home-primary-visual">
                          <iframe
                            allowFullScreen
                            title={item.primaryVisuals.video.url}
                            src={getVideoEmbedUrl(
                              item.primaryVisuals.video.url
                            )}
                          ></iframe>
                        </div>
                      ) : item.primaryVisuals?.thumbnail ? (
                        <Image
                          image={item.primaryVisuals.thumbnail}
                          aspectRatio="widescreen"
                          alt={item.title}
                        />
                      ) : (
                        ''
                      )}
                    </Link>
                    <div className="home-story-text home-main-story-text">
                      <CategoryByline
                        publishDate={item.publishDate}
                        updatedPublishDate={item.updatedPublishDate}
                        primaryCollection={item.primaryCollection}
                      />
                      <p>{item.descriptionText}</p>
                      {item.collectionRelatedLinks?.length ? (
                        <ul>
                          {item.collectionRelatedLinks.map((link) => {
                            const { as } = analyzeUrl(link.url);
                            return (
                              <li
                                key={`${link.url}${link.title}${link.prefix}`}
                              >
                                {link.prefix ? (
                                  <span className="related-prefix">
                                    {link.prefix}
                                  </span>
                                ) : (
                                  ''
                                )}
                                <Nlink
                                  href={as || link.url}
                                  className="related_link"
                                >
                                  {link.title}
                                </Nlink>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                      <div className="home-main-story-controls">
                        {item?.audio && item?.audio[0]?.encodings[0] ? (
                          <AudioPlayButton
                            label="Listen"
                            showTitle
                            audioSource={audioDownloadPrefix(
                              item.audio[0].encodings[0].playFilePath
                            )}
                            audioThumbnailSrc={audioSquareImage}
                            audioThumbnailAlt={
                              audioSquareImage &&
                              item.primaryVisuals?.lead?.shortCaption
                            }
                            showDuration={`${
                              item.audio[0].encodings[0].durationHms ?? ''
                            }`}
                          />
                        ) : (
                          <span></span> /* ensures save button stays at end */
                        )}
                        <AddFavoriteWrapper
                          title={item.title}
                          contentArea="mprnews"
                          resourceType={item.resourceType}
                          id={item.id}
                          slug={item.canonicalSlug}
                          showIcon={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </section>
    </article>
  );
};

export default FeaturedStories;
